export const environment = {
  production: true,
  env: "production",
  firebaseConfig: {
    apiKey: "AIzaSyBe3ru9QcSCqBSGUqtNTdxcV9_Ykbee4f0",
    authDomain: "heatpuls.firebaseapp.com",
    databaseURL: "https://heatpuls.firebaseio.com",
    projectId: "heatpuls",
    storageBucket: "heatpuls.appspot.com",
    messagingSenderId: "830768269092",
    appId: "1:830768269092:web:545702ea7400e90c77d5b1",
  },
  BACKEND_BASE_URL: "/d/",
  AI_URL: "/ai/",
  version: "energieklus@" + "3.12.2", // NOTE: DO NOT MODIFY: Updated with 3.12.2 during build process
};
